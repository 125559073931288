import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"

const FaqPage = () => (
  <Layout>
    <Seo title="FAQ for OC Beach Wax" description="Learn more about OC Beach Wax, the #1 waxing and facials business in Ocean City, MD." />
    <div className="row justify-content-center page-header">
        <div className="stripers">&nbsp;</div>
        <div className="col-11">
            <h1>Waxing &amp; Facials FAQ</h1>
            <p>Learn more about OC Beach Wax, the #1 waxing and facials business in Ocean City, MD.</p>
        </div>        
    </div>
    <div className="row justify-content-center page-content">
        <div className="col-11 page-section">
            <div className="page-section-text">
                <h2>Appointments</h2>

                <h3>Scheduling</h3>

                <p>We do accept walk ins but highly recommend making an appointment.&nbsp;</p>


                <h3>Late / Rescheduling</h3>

                <p>Please be advised that if you are more than 5-10 minutes late for your appointment you may have to reschedule. Service lengths vary and are set according to time needed to complete. Summer traffic can play a big part in this so please be mindful when scheduling your next appointment.</p>

                <h3>Hours</h3>

                <p>Our Summer hours of operation begin in July. We will then be open Tuesday through Saturday.</p>

                <h2>Waxing</h2>

                <h3>What exactly is a Brazilian wax?</h3>

                <p>A Brazilian wax removes all hair from the pubic region from the top to the labia and even between the bum cheeks.</p>

                <h3>How often should I wax?</h3>

                <p>Most wax worshipers wax every 3-6 weeks. We find that 4&nbsp;weeks is best if you are new to waxing.</p>

                <h3>Should I get waxed the day before a big event?</h3>

                <p>If it's your first time waxing we recommend booking your wax 2 or 3 days before a big event such as your wedding. Don't worry, you will still be silky smooth for your big day!</p>

                <h3>When should I NOT wax?</h3>

                <p>You should never wax if you are on an acne medication such as Accutane or any other skin thinning or blood thinning medications. Waxing while on these medications can cause the skin to lift. If you have undergone cosmetic treatments such as chemical peels you may have to wait up to 6 months before it is safe to wax.</p>

                <h3>Things To Know</h3>

                <ul>
                <li>Hair should be at least 1/4 inch long. That's about the length of a grain of rice. Keep in mind the longer the better!! Do not trim or shave any area prior to your wax appointment.</li>
                <li>Don't apply creams, lotions or gels to the area being waxed the day of your appointment.</li>
                <li>If you have a low pain tolerance, take an aspirin or ibuprofen 30 min before your appointment to reduce discomfort.</li>
                <li>Wear comfortable clothing, you don't want super tight clothing irritating any freshly waxed areas.</li>
                </ul>

                <ul>
                <li>
                <p>Possible side effects include but are not limited to: mild redness, extreme</p>

                <p>redness, bruising, local swelling, stinging, tenderness, dry skin, flaking</p>

                <p>scabbing , lightening or darkening of the skin, infections, pimples, bumpy</p>

                <p>appearance, and cold sores. Most side effects are temporary and generally fade within 72 hours.&nbsp;</p>
                </li>
                </ul>
                
            </div>
        </div>
        
    </div>
    
  </Layout>
)

export default FaqPage
